<template>
  <div class="alarmTicketAdd">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="120px" size="small">
          <el-row>
          <!-- <el-col :span="12">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所" @change="homeChange">
              <el-option v-for="(item,index) in homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          </el-col> -->
          <el-col :span="12">
          <el-form-item label="类型:" prop="ticketType">
            <el-select v-model="inform.ticketType" placeholder="类型">
              <el-option v-for="(item,index) in typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="等级:" prop="ticketLevel">
            <el-select v-model="inform.ticketLevel" placeholder="等级">
              <el-option label="特急" value="1"></el-option>
              <el-option label="紧急" value="2"></el-option>
              <el-option label="较急" value="3"></el-option>
              <el-option label="一般" value="4"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="处理人:" prop="handlerUser">
            <el-select v-model="inform.handlerUser" placeholder="处理人">
              <el-option label="暂不派单" value=""></el-option>
              <el-option v-for="(item,index) in userList" :key="index" :label="item.name" :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          </el-row>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        // homeId:'',//场所
        ticketType:'',//类型
        ticketLevel:'',//级别
        handlerUser:'',//处理人
        remark:'',//备注
        alarmIdList:[],//告警ID
      },
      userList:[],//处理人列表
      typeData:[],//工单类型
      homeList:[],//场所列表

      rules :{
        // homeId: [
        //   { required: true, message: '请选择场所', trigger: 'change' },
        // ],
        ticketLevel: [
          { required: true, message: '请选择工单等级', trigger: 'change' },
        ],
        ticketType: [
          { required: true, message: '请选择工单类型', trigger: 'change' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,ids){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      vm.isDio = true
      vm.getTicketType()
      vm.ticketGetPerson()
      // vm.getHome()
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建工单'
          vm.inform.alarmIdList = ids
        }
      })
    },
    //获取场所
		async getHome(){
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if(res?.code == 200){
        this.homeList = res.data
      }
		},
    //场所改变
    homeChange(val){
      vm.ticketGetPerson()
    },
    //校验form
    checkForm (formEl,type){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addTicket()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.userList = []
      vm.typeData = []
      vm.homeList = []
      // vm.inform.roleIds = []
      vm.inform = {
        id:'',
        // homeId:'',//场所
        ticketType:'',//类型
        ticketLevel:'',//级别
        handlerUser:'',//处理人
        remark:'',//备注
        alarmIdList:[],//告警ID
      }
      vm.isDio = false
    },
    //获取处理人
    async ticketGetPerson (){
      let csType = vm.$store.state.csType
      const res = await apiUrl.ticketGetPerson(csType)
      if(res?.code==200){
        res.data?.forEach(item =>{
          if(item.personName){
            item.name = item.account+'('+item.personName+')'
          }else{
            item.name = item.account
          }
        })
        vm.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取设备类型
    async getTicketType (){
      let type = '1'//1告警，2非告警
      const res = await apiUrl.getTicketType(type)
      if(res?.code==200){
        vm.typeData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //新增
    async addTicket(){
      let dataObj = {
        // 'homeId':vm.inform.homeId,//场所
        'areaId':vm.$store.state.csType,//区域
        'ticketType':vm.inform.ticketType,//类型
        'ticketLevel':vm.inform.ticketLevel,//级别
        'handlerUser':vm.inform.handlerUser,//处理人
        'remark':vm.inform.remark,//备注
        'alarmIdList':vm.inform.alarmIdList,//告警id
      }
      if(vm.inform.ticketType=='4'){
        if(this.inform.deviceInstallList.length>0){
          const isNot = vm.hasEmptyFields(this.inform.deviceInstallList,['deviceTypeName', 'installNumber'])
          if(isNot){
            vm.$message.warning('安装设备数据不能为空！')
            return
          }
        }
        dataObj.deviceInstallList = vm.inform.deviceInstallList
      }
      const res = await apiUrl.addTicket(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
  }
}
</script>
<style lang='scss'>
.alarmTicketAdd{
  .contDio{
    max-height: 500px;
    overflow: auto;
  }
}
</style>