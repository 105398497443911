<template>
  <div class='safetyAlarmLogs mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name">
        <div>检索条件</div>
      </div>
      <el-form ref="searchForm" :model="keyWord" label-width="50px" inline @submit.native.prevent size="small">
        <el-form-item label="时间:" prop="time">
          <el-date-picker class="timePicker" v-model="keyWord.time" value-format="yyyy-MM-dd" clearable
                          :default-time="['00:00:00', '23:59:59']" @change="getList(1)" type="daterange"
                          range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
        </el-form-item>
        <!-- <el-form-item label="设备名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入设备名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item> -->
        <el-form-item label="类别:" prop="alarmCategory">
          <el-select v-model="keyWord.alarmCategory" placeholder="告警类别" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.categoryData" :key="index" :label="item.dictName"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="keyWord.type" placeholder="告警类型" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.typeData2" :key="index" :label="item.dictName"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="keyWord.status" placeholder="告警状态" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" class="primary" v-if="isAuth('safe:safety:alarm:logs:ticket')" @click="batchTicket()">
            <i class="el-icon-position"></i>生成工单
          </el-button>
          <el-button type="text" class="error" v-if="isAdmin" @click="batchCancel()"><i
              class="el-icon-circle-close"></i>取消告警
          </el-button>
          <el-button type="text" class="warning" v-if="isAuth('safe:safety:alarm:logs:export')" @click="alarmExport()">
            <i class="icon icon-daochu"></i>导出
          </el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed :selectable="selectable"></el-table-column>
          <el-table-column prop="homeName" label="场所" width="150"/>
          <el-table-column prop="devName" label="告警设备" show-overflow-tooltip width="200"/>
          <el-table-column :formatter="formatCate" label="告警类别"/>
          <el-table-column prop="alarmTypeName" label="告警类型"/>
          <el-table-column prop="alarmSubTypeName" label="二级类型" width="100"/>
          <el-table-column prop="alarmCount" label="接警次数"/>
          <el-table-column prop="createTime" label="告警时间" width="150" show-overflow-tooltip/>
          <el-table-column prop="endAlarmTime" label="最后告警时间" width="150" show-overflow-tooltip/>
          <el-table-column prop="notifyTime" label="处警时间" width="150" show-overflow-tooltip/>
          <el-table-column prop="handleTime" label="处理时间" width="150" show-overflow-tooltip/>
          <el-table-column label="状态" :formatter="formatStatus" width="100" fixed="right"></el-table-column>
          <el-table-column label="操作" width="250" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary"
                         v-if="isAuth('safe:safety:alarm:logs:ticket')&&(scope.row.alarmStatus == '0' || scope.row.alarmStatus == '1')&&!scope.row.ticketId"
                         @click="openDio('ticket',scope.row)"><i class="el-icon-position"></i>生成工单
              </el-button>
              <el-button type="text" class="primary"
                         v-if="isAuth('safe:safety:alarm:logs:detail')&&scope.row.ticketId"
                         @click="openDio('look',scope.row)"><i class="el-icon-view"></i>查看工单
              </el-button>
              <el-button type="text" class="error"
                         v-if="scope.row.alarmStatus != '3' && isAdmin"
                         @click="openDio('cancel',scope.row)"><i class="el-icon-circle-close"></i>取消告警
              </el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:safety:alarm:logs:detail')"
                         @click="openDio('detail',scope.row)"><i class="el-icon-s-order"></i>详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--查看-->
    <alarm-logs-detail ref="detailDio"></alarm-logs-detail>

    <!--创建工单-->
    <alarm-ticket-add ref="ticketDio" @addEditSucc="getList()"></alarm-ticket-add>

    <!--查看工单-->
    <ticket-view ref="ticketViewDio" :dicObj="dicObj"></ticket-view>
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import alarmLogsDetail from './alarm-logs-detail'
import alarmTicketAdd from './alarm-ticket-add'
import ticketView from '@/views/system/config/ticket/ticket-add-edit'
import {apiUrl} from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils'

var vm;
export default {
  name: 'safety-alarm-logs',
  data() {
    return {
      //筛选数据
      keyWord: {
        name: '',
        time: '',
        alarmCategory: '',
        status: '',
        type: '',
      },
      //字典数据
      dicObj: {
        categoryData: [],//告警类别
        typeData2: [],//告警类型
        typeData: [],//工单类型
        statusData: [],//告警状态
      },
      //分页
      curr: 1,//当前页
      limits: 10,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],

      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    alarmLogsDetail,
    alarmTicketAdd,
    ticketView,
    breadCrumb,
  },
  watch: {
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.getList(1)
      }
    }
  },
  async created() {
    vm = this
    vm.initHeight()
    await vm.getCurrDics()
    let userInfo = this.vtp.get('userInfo')
    if (userInfo) {
      this.isAdmin = userInfo.isAdmin
    }
    let query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.keyWord.status = query.alarmStatus
      this.keyWord.type = query.alarmType
      this.keyWord.alarmCategory = query.alarmCategory
    }
  },
  async mounted() {
    if (vm.$store.state.homeId != null) vm.getList()
    window.onresize = () => {
      vm.initHeight()
    }
  },
  //方法
  methods: {
    //校验行是否禁用
    selectable: function (row, index) {
      if (row.alarmStatus != '3') {
        return true
      } else {
        return false
      }
    },
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'cancel') {
        vm.$confirm('确定取消告警？', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.alarmHandle(arr)
            })
            .catch(() => {
            })
      } else {
        if (type == 'detail') {
          vm.$refs.detailDio.init(type, item);//详情
        } else if (type == 'ticket') {
          vm.$refs.ticketDio.init('add', [item.id]);//创建工单
        } else if (type == 'look') {
          vm.ticketView(item.id);//查看工单
        }
      }
    },
    //获取工单id
    async ticketView(id) {
      const res = await apiUrl.ticketView(id)
      if (res?.code == 200) {
        vm.$refs.ticketViewDio.init('detail', res.data.ticketModel?.id);//查看工单
      } else {
        vm.$message.error(res.message)
      }
    },
    //批量生成工单
    batchTicket() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let arr = []
      for (var i in vm.tableActive) {
        arr.push(vm.tableActive[i].id)
      }
      vm.$refs.ticketDio.init('add', arr);//创建工单
    },
    //判断数组中所有类型是否一致
    isTypeEqual(arr) {
      if (arr.length === 0) {
        return true; // 数组为空时返回true
      }
      for (let i = 0; i < arr.length; i++) {
        //未处理和已处警可取消告警
        if (arr[i].alarmStatus != '0' && arr[i].alarmStatus != '1') {
          return false; // 如果有一个对象的type不是0，就返回false
        }
      }
      return true; // 所有对象的type都相同，返回true
    },
    //批量取消告警
    batchCancel() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      if (vm.isTypeEqual(vm.tableActive) == false) {//判断未处理状态
        vm.$message.warning('请勾选未处理的告警数据')
        return
      }
      this.$confirm('是否确定要取消告警？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for (var i in vm.tableActive) {
          arr.push(vm.tableActive[i].id)
        }
        vm.alarmHandle(arr);
      }).catch(() => {
      });
    },
    //取消告警
    async alarmHandle(ids) {
      let dataObj = {
        'id': ids.join(','),
        'alarmStatus': 3
      }
      const res = await apiUrl.alarmHandle(dataObj)
      if (res?.code == 200) {
        vm.$message.success('取消成功')
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //导出告警
    async alarmExport() {
      let fileNameMy = new Date().getTime() + '告警列表'
      let dataObj = {
        'fileName': fileNameMy,
        'homeId': vm.$store.state.homeId,
        'startTime': vm.keyWord.time ? vm.keyWord.time[0] : '',
        'endTime': vm.keyWord.time ? vm.keyWord.time[1] : '',
        'alarmCategory': vm.keyWord.alarmCategory,
        'status': vm.keyWord.status,
        'pageNo': vm.curr,
        'pageSize': vm.limits
      }
      const res = await apiUrl.alarmExport(dataObj)
      if (!res.data) return;
      //fileName:下载的文件名
      // var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});

      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileNameMy; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    },
    //过滤状态
    formatStatus(row, column) {
      let result = vm.dicObj.statusData.filter(item => item.dictValue == row.alarmStatus)
      return result[0].dictValue == '0' ? <span class="warning">{result[0].dictName}</span>
          : result[0].dictValue == '1' ? <span class="primary">{result[0].dictName}</span>
              : result[0].dictValue == '2' ? <span class="success">{result[0].dictName}</span>
                  : <span class="info">{result[0].dictName}</span>
    },
    //过滤时间
    formatLastTime(row, column) {
      return row.lastLoginTime ? formatTime(row.lastLoginTime, 'Y-M-D h:m:s') : '-'
    },
    //过滤告警类别
    formatCate(row, column) {
      let result = vm.dicObj.categoryData.filter(item => item.dictValue == row.alarmCategory)
      return result[0]?.dictName
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'startTime': vm.keyWord.time ? vm.keyWord.time[0] : '',
        'endTime': vm.keyWord.time ? vm.keyWord.time[1] : '',
        'alarmCategory': vm.keyWord.alarmCategory,
        'alarmType': vm.keyWord.type,
        'status': vm.keyWord.status,
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits
      }
      const res = await apiUrl.deviceAlarmList(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr ? _curr : vm.curr
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics() {
      let dataObj = ['ALARM_CATEGORY', 'ALARM_STATUS', 'ALARM_TYPE', 'TICKET_TYPE']
      const res = await apiUrl.getDicts(dataObj)
      if (res?.code == 200) {
        vm.dicObj.categoryData = res.data.ALARM_CATEGORY || [];//告警类别
        vm.dicObj.typeData2 = res.data.ALARM_TYPE || [];//告警类型
        vm.dicObj.typeData = res.data.TICKET_TYPE || [];//工单类型
        vm.dicObj.statusData = res.data.ALARM_STATUS || [];//告警状态
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 322
    }
  }
}
</script>
<style lang='scss'>
.safetyAlarmLogs {
  .timePicker {
    width: 230px;
  }
}
</style>
