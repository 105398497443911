<template>
  <div class="alarmLogsDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio">
        <el-form class="contDio" ref="dioform" :model="inform" label-width="100px" size="small">
          <el-row>
          <el-col :span="12">
          <el-form-item label="设备名称:">{{inform.devName}}</el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="场所:">{{inform.homeName}}</el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="设备编号:">{{inform.devNo}}</el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="告警类型:">{{inform.alarmTypeName}}</el-form-item>
          </el-col>
            <el-col :span="12">
          <el-form-item label="其他:">{{inform.remark}}</el-form-item>
          </el-col>
          </el-row>
        </el-form>
        <el-table v-if="inform.detailMap.data" class="tableCont" :data="inform.detailMap.data" stripe :height="400" style="width:100%">
          <el-table-column type="index" label="序号" width="60"/>
          <el-table-column :formatter="timeFormat" label="告警时间" width="200"/>
          <el-table-column prop="content" label="告警内容"/>
          <el-table-column label="操作" width="65" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('detail',scope.row)"><i
                  class="el-icon-s-order"></i>数据
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
      </span>
    </el-dialog>
    <alarm-data-detail ref="alarmDetail" @closeDio="closeDio()"></alarm-data-detail>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
import alarmDataDetail from '../historylogs/alarm-data-detail.vue'
var vm;
export default {
  data() {
    return {

      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        devName:'',//设备名称
        homeName:'',//场所名称
        devNo:'',//设备编号
        detailMap:{
          data:[]
        }
      },
    }
  },
  components:{
    alarmDataDetail
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '750px'
      vm.isDio = true

      vm.$nextTick(() => {
        if(type == 'detail'){
          vm.dioTit = '告警详情'
          vm.deviceAlarmDetail(item.id)
        }
      })
    },
    async deviceAlarmDetail(id){
      const res = await apiUrl.deviceAlarmDetail(id)
      if(res?.code == 200){
        vm.inform = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤时间
    timeFormat(row,column){
      return row.alarm_time?formatTime(row.alarm_time,'Y-M-D h:m:s'):'-'
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        // vm.$refs[formEl].resetFields()
      // })
      vm.inform={
        id:'',
        devName:'',//设备名称
        homeName:'',//场所名称
        devNo:'',//设备编号
        detailMap:{
          data:[]
        }
      }
      vm.isDio = false
    },
    //打开弹窗
    openDio(type, item) {
      console.log(type, item);
      if (type == 'detail') {
        vm.$refs.alarmDetail.init(type, item);//详情
      }
    },
  }
}
</script>
<style lang='scss'>
.alarmLogsDetail{
  .contDio{
    max-height: 550px;
    overflow: auto;
  }
}
</style>